<template>
  <div class="kefu-iframe">
     <!-- 客服 iframe -->
    <iframe
      class="frame"
      :src="url"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:[],
      id:'',
      name:'',
      avatar: '',
      url: ''
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.id = this.userInfo.id
    this.name = this.userInfo.mobile
    this.avatar = this.userInfo.image
    // console.log(this.$route.params,'=====--=====')
    this.url = `${this.$route.params.url}/index/index/home?theme=7571f9&visiter_id=${this.id}&visiter_name=${this.name}&avatar=${this.avatar}&business_id=1&groupid=0`
  }
};
</script>

<style lang="less" scoped>

.kefu-iframe{
  position: fixed;
  top: 75px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 75px);
  z-index: 999999999;
  box-sizing: border-box;
  .frame{
    width: 100%;
    height: 100%;
  }
}
</style>